<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field type="text" hide-details filled label="Абонент" disabled v-model="flat_name" color="grey"
            />
          </v-col>
          <v-col cols="12" md="12">
            <ACC_ContractorContract :value="contract_id" @autocompleteChange="contractChange" :person_hash="person_hash"
                                    :contractor_name="flat_name"
                                    :class="contract_id ? '' : 'req-star'" color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <AC_TariffGroup :value="tariff_group_type" :class="tariff_group_type ? '' : 'req-star'" @autocompleteChange="autocompleteChange"/>
          </v-col>
          <v-col cols="12" md="6">
            <date-component v-model="date_start" req label="Дата початку"/>
          </v-col>
          <v-col cols="12" md="6">
            <date-component v-model="date_end" label="Дата закінчення"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="createGroup" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import {CREATE_TARIFF_LEGACY_GROUP, DELETE_TARIFF_LEGACY_GROUP, UPDATE_TARIFF_LEGACY_GROUP} from "@/store/actions/tariff_group";
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = "tariff_legacy_group_modal_id"

export default {
  name: "HWP_Modal_TariffLegacyGroups",
  mixins: [ModalComponentMixin],
  components: {
    AC_TariffGroup: () => import("@/components/autocomplite/AC_TariffGroup"),
    ACC_ContractorContract: () => import("@/components/accounting/autocomplite/modal/ACC_ContractorContract")

  },
  computed: {
    ...mapGetters({
      tariff_group_type_select: 'getTariffGroupSelect',
    }),
  },
  data() {
    return {
      flat_name: this.item.flat_name,
      contract_id: this.item.contract_id || null,
      person_hash: this.item.person_hash || '',
      name: this.item.name || '',
      tariff_group_type: this.item.tariff_group_type || null,
      date_start: this.item.date_start || null,
      date_end: this.item.date_start || null,
    }
  },
  methods: {
    contractChange(payload) {
      this.contract_id = payload?.value || null
    },
    autocompleteChange(payload) {
      this.tariff_group_type = payload.value || null
    },
    closeModal(payload=null) {
      this.$emit('closeModal', payload)
      this.itemId = this.item.id || undefined
      this.person_hash = this.item.person_hash || ''
      this.flat_name = this.item.flat_name || ''
      this.contract_id = this.item.contract_id || null
      this.name = this.item.name || ''
      this.tariff_group_type = this.item.tariff_group_type || null
      this.date_start = this.item.date_start || ''
      this.date_end = this.item.date_end || ''

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    createGroup() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Група тарифу та дата початку мають бути заповнені',
          color: 'error lighten-1'
        })
        return undefined
      }

      const payload = {
        person_hash: this.person_hash,
        contract_id: this.contract_id,
        name: this.name,
        tariff_group_type_id: this.tariff_group_type,
        date_start: this.date_start ? this.date_start : null,
        date_end: this.date_end ? this.date_end : null,
      }


      if (this.isNew) {
        this.$store.dispatch(CREATE_TARIFF_LEGACY_GROUP, payload)
            .then(res => {
              if (res) {
                this.closeModal("fetch")
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_TARIFF_LEGACY_GROUP, payload)
            .then(res => {
              if (res) {
                this.closeModal("fetch")
              }
            })
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення групи тарифу: ${this.name}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.flat_name = payload.flat_name
              this.contract_id = payload.contract_id
              this.person_hash = payload.person_hash
              this.name = payload.name
              this.tariff_group_type = payload.tariff_group_type
              this.date_start = payload.date_start
              this.date_end = payload.date_end
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_TARIFF_LEGACY_GROUP, this.id)
                      .then(ok => {
                        if (ok) {
                          this.closeModal("fetch")
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>
