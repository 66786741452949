import { mapGetters } from 'vuex'

export default {
    props: ['title', 'item', 'dialog'],
    computed: {
        ...mapGetters({
            modalAnswer: 'question_answer',
            getModalLoading: 'get_modal_loading'
        }),
        isNew() {
            return this.item.id === undefined || this.item.id == null
        }
    },
    data() {
        return {
            formValid: false,
            watcher: null,
            delete_watcher: null,
            dialog_watcher: null,
            itemId: this.item.id,
            menu: false,
        }
    },
    created() {
        this.watch_dialog()
    },
    beforeDestroy() {
        if (this.watcher) {
            this.watcher()
        }
        if (this.delete_watcher) {
            this.delete_watcher()
        }
        if (this.dialog_watcher) {
            this.dialog_watcher()
        }
    }
}